import React, { Component } from 'react';
import {Link, Navigate} from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FadeIn from "react-fade-in";
import Container from "react-bootstrap/Container";
import { useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';



class Page00 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pass: '',
            key: '',
            redirect: null
        };
        this.handlePass = this.handlePass.bind(this);
        this.handleKey = this.handleKey.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handlePass(event) {
        this.setState({pass: event.target.value});
    }
    handleKey(event) {
        this.setState({key: event.target.value});
    }
    handleSubmit(event) {
        let CryptoJS = require("crypto-js");
        let check1 = "6fc5eed47a550a193d4232a3ffb3f104a1820f7551c7bdd039e5678f46993c7d5fea076fdbed6269e883d8785c1d81de804148e0ab1aec77d5bc7b4e34cb0e95";
        let check2 = "f7803e0004148d808d45b5b920734d8a9c7bb6f447c20ef0d3830f4ddd112a81698f9a743054ebd51b1de02251013e628dbc6a9296d64c5cf39e82086043967a";

        var hashpass = CryptoJS.SHA3(this.state.pass).toString();
        var hashkey = CryptoJS.SHA3(this.state.key).toString();

        if(hashpass === check2 && hashkey === check1){
            this.props.handler();
            this.setState({redirect: true});
            localStorage.setItem('redirect', "true");
        }
        else{
            this.setState({pass: '', key : ''});
        }

        event.preventDefault();


    }

    componentDidMount() {
        //const redirect = localStorage.getItem('redirect');
        //this.setState({redirect: redirect})
    }
    render() {
        /*{this.state.signedIn ? (
            <button onClick={this.logOut}>Logout</button>
        ) : (
            <button onClick={this.logIn}>Login</button>
        )}*/
        return (
            <div className={"d-flex justify-content-center pt-5 pt-lg-3 mb-5"}>
                {this.state.redirect && <Navigate to="/Page0"/>}
                    <Container fluid className={"pt-5 pt-lg-3"}>
                        <FadeIn>
                            <Row className={"justify-content-center d-flex pt-5 pt-lg-1"}>
                                <h2 className={"justify-content-center d-flex pt-5 pt-lg-1"}>Sign In</h2>
                                <Form onSubmit={this.handleSubmit} style={{width: "35vh"}}>
                                    <Row>
                                        <Form.Label>
                                            Username:
                                            <Form.Control type="password" value={this.state.key} onChange={this.handleKey} />
                                        </Form.Label>
                                    </Row>
                                    <Row>
                                        <Form.Label>
                                            Password:
                                            <Form.Control type="password" value={this.state.pass} onChange={this.handlePass} />
                                        </Form.Label>

                                    </Row>


                                    <Button variant="danger" type="submit">
                                        Submit
                                    </Button>
                                </Form>

                            </Row>




                        </FadeIn>

                    </Container>


            </div>

        );
    }
}
export default Page00;