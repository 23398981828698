import {Outlet, Link, useNavigate, Redirect, Navigate} from "react-router-dom";
import React, {Component} from "react";

import {Navbar, NavLink, Container, Nav, Button} from "react-bootstrap";
import Offcanvas from 'react-bootstrap/Offcanvas';


class Layout extends Component {
    constructor(props) {
        super(props);
        console.log(this.props.signedIn);
        this.state = {
            menuShow: false,
        }
    }


    updateCounter = () =>{
        this.setState({menuShow: this.state.menuShow, counter: this.state.counter + 1});
        console.log("updated counter");
    };

    handleClose = () => {
        this.setState({menuShow: false});
    };
    handleClose2 = () => {
        this.setState({menuShow: false});
        this.props.handler();
    };
    handleShow = () => {
        this.setState({menuShow: true});
    };
// <!--<Nav.Link as={Link} onClick={this.handleClose} to="/AboutMe">Calender</Nav.Link>-->


    render(){
        return(
            <>
                {this.props.signedIn && <Navbar key="lg" bg="light" expand="lg">
                    <Container className={"d-flex justify-content-center"}>
                        <Navbar.Brand as={Link} to="/Page0">Happy One Year Anniversary ❤️</Navbar.Brand>
                        <Navbar.Toggle aria-controls="offcanvasNavbar-expand-lg" onClick={this.handleShow}/>

                        <Navbar.Offcanvas
                            id="offcanvasNavbar-expand-lg"
                            aria-labelledby="offcanvasNavbarLabel-expand-lg"
                            placement="end"
                            restoreFocus={false}
                            show={this.state.menuShow}
                            onHide={this.handleClose}
                        >
                            <Offcanvas.Header closeButton>
                                <Offcanvas.Title id="offcanvasNavbar-expand-lg">
                                    David + Mirang &lt;3
                                </Offcanvas.Title>
                            </Offcanvas.Header>
                            <Offcanvas.Body className="justify-content-end">
                                <Nav>
                                    <Nav.Link as={Link} onClick={this.handleClose} to="/Page0">Home</Nav.Link>

                                    <Nav.Link as={Link} onClick={this.handleClose2} to="/">Log Out</Nav.Link>
                                </Nav>
                            </Offcanvas.Body>
                        </Navbar.Offcanvas>
                    </Container>
                </Navbar>}

                < Outlet />





            </>
        );
    }

};

export default Layout;