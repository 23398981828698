import { Navigate } from "react-router-dom";
import React from "react";
const Protected = ({ isLoggedIn, children }) => {
    let s = localStorage.getItem('signedIn');
    console.log(s);
    if (!s) {
        return <Navigate to="/" replace />;
    }
    return children;
};
export default Protected;