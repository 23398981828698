import React, { Component } from 'react';
import {Link} from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FadeIn from "react-fade-in";
import Container from "react-bootstrap/Container";
import {Gallery} from "react-grid-gallery";
import Lightbox from "react-image-lightbox";
import {Button} from "react-bootstrap"
import "react-image-lightbox/style.css";


class Page3 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            index: -1,
            images : [
                {
                    src: require("./img/20211209_192209.jpg"),
                    width: 4032,
                    height: 3024,
                },
                {
                    src: require("./img/20211209_171230.jpg"),
                    width: 3024,
                    height: 4032,
                },
                {
                    src: require("./img/20211209_192808.jpg"),
                    width: 3024,
                    height: 4032,
                },
                {
                    src: require("./img2/20211209_182729.jpg"),
                    width: 3024,
                    height: 4032,
                },
                {
                    src: require("./img2/20211209_191427.jpg"),
                    width: 3024,
                    height: 4032,
                }
            ],
        };
        this.handleClick = this.handleClick.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleMovePrev = this.handleMovePrev.bind(this);
        this.handleMoveNext = this.handleMoveNext.bind(this);
    }


    handleClick = () => {this.setState({index : (this.state.index + 1) % this.state.images.length})};
    handleClose = () => this.setState({index : -1});
    handleMovePrev = () => this.setState({index : (this.state.index + this.state.images.length - 1) % this.state.images.length});;
    handleMoveNext = () => this.setState({index : (this.state.index + 1) % this.state.images.length});
    render() {
        let currentImage = this.state.images[this.state.index];
        let nextIndex = (this.state.index + 1) % this.state.images.length;
        let nextImage = this.state.images[this.nextIndex] || currentImage;
        let prevIndex = (this.state.index + this.state.images.length - 1) % this.state.images.length;
        let prevImage = this.state.images[prevIndex] || currentImage;


        return (

            <div className={"d-flex justify-content-center pt-5"}>


                <Container fluid className={""}>
                    <FadeIn>
                        <Row>
                            <h2 className={"justify-content-center d-flex"}>Our First Koreatown Adventure!</h2>
                        </Row>
                        <Row className={"justify-content-center d-flex"}>
                            <Gallery
                                images={this.state.images}
                                onClick={this.handleClick}
                                enableImageSelection={false}
                                rowHeight={300}
                                margin={3}
                            />
                            {!!currentImage && (
                                <Lightbox
                                    mainSrc={currentImage.src}
                                    mainSrcThumbnail={currentImage.src}
                                    nextSrc={nextImage.src}
                                    nextSrcThumbnail={nextImage.src}
                                    prevSrc={prevImage.src}
                                    prevSrcThumbnail={prevImage.src}
                                    onCloseRequest={this.handleClose}
                                    onMovePrevRequest={this.handleMovePrev}
                                    onMoveNextRequest={this.handleMoveNext}
                                />
                            )}

                        </Row>
                        <Row>

                            <h3 className={"justify-content-center d-flex p-2"}>December 9, 2021</h3>

                            
                            <Row><Col></Col><Col><Link to={"/Page2"} style={{textDecoration: "none"}} className={"d-flex justify-content-center pt-2"}><Button variant={"danger"} className={"pt-2 but"}>Previous</Button></Link></Col><Col><Link to={"/Page4"} style={{textDecoration: "none"}} className={"d-flex justify-content-center pt-2"}><Button variant={"danger"} className={"pt-2 but"}>Next</Button></Link></Col><Col></Col></Row>
                        </Row>




                    </FadeIn>

                </Container>



            </div>

        );
    }
}
export default Page3;