import Lightbox from "react-image-lightbox";
import React, { Component } from 'react';
import {Link} from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FadeIn from "react-fade-in";
import Container from "react-bootstrap/Container";
import {Gallery} from "react-grid-gallery";
import {Button} from "react-bootstrap";


class Page48 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            index: -1,
            images : [
                {
                    src: require("./img/20220725_163943(0).jpg"),
                    width: 4032,
                    height: 3024,
                },
                {
                    src: require("./img/20220725_162807(0).jpg"),
                    width: 4032,
                    height: 3024,
                },
                {
                    src: require("./img/20220725_155255.jpg"),
                    width: 4032,
                    height: 3024,
                },
                {
                    src: require("./img/20220725_151025.jpg"),
                    width: 4032,
                    height: 3024,
                },
                {
                    src: require("./img/20220725_135653.jpg"),
                    width: 4032,
                    height: 3024,
                },
                {
                    src: require("./img/20220725_134907.jpg"),
                    width: 4032,
                    height: 3024,
                },
                {
                    src: require("./img/20220725_133911.jpg"),
                    width: 4032,
                    height: 3024,
                },
                {
                    src: require("./img/20220725_141045.jpg"),
                    width: 4032,
                    height: 3024,
                },


            ]
        };
        this.handleClick = this.handleClick.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleMovePrev = this.handleMovePrev.bind(this);
        this.handleMoveNext = this.handleMoveNext.bind(this);
    }


    handleClick = () => {this.setState({index : (this.state.index + 1) % this.state.images.length})};
    handleClose = () => this.setState({index : -1});
    handleMovePrev = () => this.setState({index : (this.state.index + this.state.images.length - 1) % this.state.images.length});;
    handleMoveNext = () => this.setState({index : (this.state.index + 1) % this.state.images.length});
    render() {
        let currentImage = this.state.images[this.state.index];
        let nextIndex = (this.state.index + 1) % this.state.images.length;
        let nextImage = this.state.images[this.nextIndex] || currentImage;
        let prevIndex = (this.state.index + this.state.images.length - 1) % this.state.images.length;
        let prevImage = this.state.images[prevIndex] || currentImage;



        return (

            <div className={"back d-flex justify-content-center pt-3"}>


                <Container fluid className={""}>
                    <FadeIn>
                        <Row>
                            <h2 className={"justify-content-center d-flex"}>Balboa Park!</h2>

                        </Row>
                        <Row className={"justify-content-center d-flex pt-5 pt-lg-2"}>

                            <Col xs={2}></Col><Col><Gallery images={this.state.images} onClick={this.handleClick} enableImageSelection={false} rowHeight={500} margin={3}/>{!!currentImage && (<Lightbox mainSrc={currentImage.src} mainSrcThumbnail={currentImage.src} nextSrc={nextImage.src} nextSrcThumbnail={nextImage.src} prevSrc={prevImage.src} prevSrcThumbnail={prevImage.src} onCloseRequest={this.handleClose} onMovePrevRequest={this.handleMovePrev} onMoveNextRequest={this.handleMoveNext}/>)}</Col><Col xs={2}></Col>
                        </Row>
                        <Row>
                            <h3 className={"justify-content-center d-flex p-2"}>July 25, 2022</h3>

                            
                            <Row><Col></Col><Col><Link to={"/Page47"} style={{textDecoration: "none"}} className={"d-flex justify-content-center pt-2"}><Button variant={"danger"} className={"pt-2 but"}>Previous</Button></Link></Col><Col><Link to={"/Page49"} style={{textDecoration: "none"}} className={"d-flex justify-content-center pt-2"}><Button variant={"danger"} className={"pt-2 but"}>Next</Button></Link></Col><Col></Col></Row>
                        </Row>



                    </FadeIn>

                </Container>



            </div>

        );
    }
}
export default Page48;