import React, { Component } from 'react';
import {Link} from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FadeIn from "react-fade-in";
import Container from "react-bootstrap/Container";
import {Gallery} from "react-grid-gallery";
import Lightbox from "react-image-lightbox";
import {Button} from "react-bootstrap";


class Page1 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            index: -1,
            images: [
                {
                    src: require("./img/Title2.jpg"),
                    width: 1816,
                    height: 4032,
                }
            ]
        };
        this.handleClick = this.handleClick.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleMovePrev = this.handleMovePrev.bind(this);
        this.handleMoveNext = this.handleMoveNext.bind(this);
    }


    handleClick = () => {this.setState({index : (this.state.index + 1) % this.state.images.length})};
    handleClose = () => this.setState({index : -1});
    handleMovePrev = () => this.setState({index : (this.state.index + this.state.images.length - 1) % this.state.images.length});;
    handleMoveNext = () => this.setState({index : (this.state.index + 1) % this.state.images.length});
    render() {
        let currentImage = this.state.images[this.state.index];
        let nextIndex = (this.state.index + 1) % this.state.images.length;
        let nextImage = this.state.images[this.nextIndex] || currentImage;
        let prevIndex = (this.state.index + this.state.images.length - 1) % this.state.images.length;
        let prevImage = this.state.images[prevIndex] || currentImage;


        return (

            <div className={"d-flex justify-content-center pt-3"}>
                    <Container fluid className={""}>
                        <FadeIn>
                            <Row>
                                <h2 className={"justify-content-center d-flex"}>Happy One Year Anniversary🎉</h2>

                            </Row>
                            <Row className={"justify-content-center d-flex pt-5 pt-lg-2"}>
                                <Gallery
                                    images={this.state.images}
                                    onClick={this.handleClick}
                                    enableImageSelection={false}
                                    rowHeight={450}
                                    margin={3}
                                />
                                {!!currentImage && (
                                    <Lightbox
                                        mainSrc={currentImage.src}
                                        mainSrcThumbnail={currentImage.src}
                                        nextSrc={nextImage.src}
                                        nextSrcThumbnail={nextImage.src}
                                        prevSrc={prevImage.src}
                                        prevSrcThumbnail={prevImage.src}
                                        onCloseRequest={this.handleClose}
                                        onMovePrevRequest={this.handleMovePrev}
                                        onMoveNextRequest={this.handleMoveNext}
                                    />
                                )}
                            </Row>
                            <Row>

                                <h3 className={"justify-content-center d-flex p-2"}>David + Mirang ❤️</h3>

                                <h3 className={"justify-content-center d-flex p-2"}>November 28, 2022</h3>
                                
                                <Link to={"/Page1"} style={{textDecoration: "none"}} className={"d-flex justify-content-center pt-2"}><Button variant={"danger"} className={"pt-2"}>Next</Button></Link>
                            </Row>



                        </FadeIn>

                    </Container>



            </div>
        );
    }
}
export default Page1;