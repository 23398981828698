import logo from './logo.svg';
import React, {Component} from "react";
import {BrowserRouter, Link, NavLink, Route, Routes, HashRouter, Navigate} from "react-router-dom";

import './App.css';
import Layout from "./Layout";
import Page1 from "./Page1";
import Page2 from "./Page2";
import Page3 from "./Page3";
import Page4 from "./Page4";
import Page5 from "./Page5";
import Page6 from "./Page6";
import Page7 from "./Page7";
import Page8 from "./Page8";
import Page9 from "./Page9";
import Page10 from "./Page10";
import Page11 from "./Page11";
import Page12 from "./Page12";
import Page13 from "./Page13";
import Page14 from "./Page14";
import Page15 from "./Page15";
import Page16 from "./Page16";
import Page17 from "./Page17";
import Page18 from "./Page18";
import Page19 from "./Page19";
import Page20 from "./Page20";
import Page21 from "./Page21";
import Page22 from "./Page22";
import Page23 from "./Page23";
import Page24 from "./Page24";
import Page25 from "./Page25";
import Page26 from "./Page26";
import Page27 from "./Page27";
import Page28 from "./Page28";
import Page29 from "./Page29";
import Page30 from "./Page30";
import Page31 from "./Page31";
import Page32 from "./Page32";
import Page33 from "./Page33";
import Page34 from "./Page34";
import Page35 from "./Page35";
import Page36 from "./Page36";
import Page37 from "./Page37";
import Page38 from "./Page38";
import Page39 from "./Page39";
import Page40 from "./Page40";
import Page41 from "./Page41";
import Page42 from "./Page42";
import Page43 from "./Page43";
import Page44 from "./Page44";
import Page45 from "./Page45";
import Page46 from "./Page46";
import Page47 from "./Page47";
import Page48 from "./Page48";
import Page49 from "./Page49";
import Page50 from "./Page50";
import Page51 from "./Page51";
import Page52 from "./Page52";
import Page53 from "./Page53";
import Page54 from "./Page54";
import Page55 from "./Page55";
import Page56 from "./Page56";
import Page57 from "./Page57";
import Page58 from "./Page58";
import Page59 from "./Page59";
import Page60 from "./Page60";
import Page61 from "./Page61";
import Page62 from "./Page62";
import Page63 from "./Page63";
import Page64 from "./Page64";
import Page65 from "./Page65";
import Page66 from "./Page66";
import Page67 from "./Page67";
import Page68 from "./Page68";
import Page69 from "./Page69";
import Page70 from "./Page70";

import Image from "react-bootstrap/Image";
import background from "./img/heart-confetti-falling-transparent-background_87521-1080.webp"
import End from "./End";
import Page0 from "./Page0";
import Page00 from "./Page00";
import Protected from "./Protected";
import Page7_5 from "./Page7_5";
import Page8_5 from "./Page8_5";
import Page13_5 from "./Page13_5";
import Page19_5 from "./Page19_5";
import Page24_5 from "./Page24_5";
import Page32_5 from "./Page32_5";
import Page33_5 from "./Page33_5";
import Page52_5 from "./Page52_5";
import Page71 from "./Page71";
import Page72 from "./Page72";



class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      signedIn: null
    };
    this.logIn = this.logIn.bind(this);
    this.logOut = this.logOut.bind(this);

  }

  logIn = () =>{
    localStorage.setItem('signedIn', "true");
    this.setState({signedIn: "true"});

  };

  logOut = () =>{
    localStorage.clear();
    this.setState({signedIn: false});
  };

  componentDidMount() {
    const signedIn = localStorage.getItem('signedIn');
    this.setState({signedIn: signedIn})
  }


  render() {

    return (
        <div>
          <HashRouter>
            <div id={"navBar"}>
              <Routes id={"links"}>
                <Route path="/" element={<Layout signedIn={this.state.signedIn} handler={this.logOut}/>}>
                  <Route index element={<Page00 handler={this.logIn}/>} />
                  <Route path="Page0" element={<Protected isLoggedIn={this.state.signedIn}> <Page0/> </Protected>}/>
                  <Route path="Page1" element={<Protected isLoggedIn={this.state.signedIn}> <Page1/> </Protected>}/>
                  <Route path="Page2" element={<Protected isLoggedIn={this.state.signedIn}> <Page2/> </Protected>}/>
                  <Route path="Page3" element={<Protected isLoggedIn={this.state.signedIn}> <Page3/> </Protected>}/>
                  <Route path="Page4" element={<Protected isLoggedIn={this.state.signedIn}> <Page4/> </Protected>}/>
                  <Route path="Page5" element={<Protected isLoggedIn={this.state.signedIn}> <Page5/> </Protected>}/>
                  <Route path="Page6" element={<Protected isLoggedIn={this.state.signedIn}> <Page6/> </Protected>}/>
                  <Route path="Page7" element={<Protected isLoggedIn={this.state.signedIn}> <Page7/> </Protected>}/>
                  <Route path="Page7_5" element={<Protected isLoggedIn={this.state.signedIn}> <Page7_5/> </Protected>}/>
                  <Route path="Page8" element={<Protected isLoggedIn={this.state.signedIn}> <Page8/> </Protected>}/>
                  <Route path="Page8_5" element={<Protected isLoggedIn={this.state.signedIn}> <Page8_5/> </Protected>}/>
                  <Route path="Page9" element={<Protected isLoggedIn={this.state.signedIn}> <Page9/> </Protected>}/>
                  <Route path="Page10" element={<Protected isLoggedIn={this.state.signedIn}> <Page10/> </Protected>}/>
                  <Route path="Page11" element={<Protected isLoggedIn={this.state.signedIn}> <Page11/> </Protected>}/>
                  <Route path="Page12" element={<Protected isLoggedIn={this.state.signedIn}> <Page12/> </Protected>}/>
                  <Route path="Page13" element={<Protected isLoggedIn={this.state.signedIn}> <Page13/> </Protected>}/>
                  <Route path="Page13_5" element={<Protected isLoggedIn={this.state.signedIn}> <Page13_5/> </Protected>}/>
                  <Route path="Page14" element={<Protected isLoggedIn={this.state.signedIn}> <Page14/> </Protected>}/>
                  <Route path="Page15" element={<Protected isLoggedIn={this.state.signedIn}> <Page15/> </Protected>}/>
                  <Route path="Page16" element={<Protected isLoggedIn={this.state.signedIn}> <Page16/> </Protected>}/>
                  <Route path="Page17" element={<Protected isLoggedIn={this.state.signedIn}> <Page17/> </Protected>}/>
                  <Route path="Page18" element={<Protected isLoggedIn={this.state.signedIn}> <Page18/> </Protected>}/>
                  <Route path="Page19" element={<Protected isLoggedIn={this.state.signedIn}> <Page19/> </Protected>}/>
                  <Route path="Page19_5" element={<Protected isLoggedIn={this.state.signedIn}> <Page19_5/> </Protected>}/>
                  <Route path="Page20" element={<Protected isLoggedIn={this.state.signedIn}> <Page20/> </Protected>}/>
                  <Route path="Page21" element={<Protected isLoggedIn={this.state.signedIn}> <Page21/> </Protected>}/>
                  <Route path="Page22" element={<Protected isLoggedIn={this.state.signedIn}> <Page22/> </Protected>}/>
                  <Route path="Page23" element={<Protected isLoggedIn={this.state.signedIn}> <Page23/> </Protected>}/>
                  <Route path="Page24" element={<Protected isLoggedIn={this.state.signedIn}> <Page24/> </Protected>}/>
                  <Route path="Page24_5" element={<Protected isLoggedIn={this.state.signedIn}> <Page24_5/> </Protected>}/>
                  <Route path="Page25" element={<Protected isLoggedIn={this.state.signedIn}> <Page25/> </Protected>}/>
                  <Route path="Page26" element={<Protected isLoggedIn={this.state.signedIn}> <Page26/> </Protected>}/>
                  <Route path="Page27" element={<Protected isLoggedIn={this.state.signedIn}> <Page27/> </Protected>}/>
                  <Route path="Page28" element={<Protected isLoggedIn={this.state.signedIn}> <Page28/> </Protected>}/>
                  <Route path="Page29" element={<Protected isLoggedIn={this.state.signedIn}> <Page29/> </Protected>}/>
                  <Route path="Page30" element={<Protected isLoggedIn={this.state.signedIn}> <Page30/> </Protected>}/>
                  <Route path="Page31" element={<Protected isLoggedIn={this.state.signedIn}> <Page31/> </Protected>}/>
                  <Route path="Page32" element={<Protected isLoggedIn={this.state.signedIn}> <Page32/> </Protected>}/>
                  <Route path="Page32_5" element={<Protected isLoggedIn={this.state.signedIn}> <Page32_5/> </Protected>}/>
                  <Route path="Page33" element={<Protected isLoggedIn={this.state.signedIn}> <Page33/> </Protected>}/>
                  <Route path="Page33_5" element={<Protected isLoggedIn={this.state.signedIn}> <Page33_5/> </Protected>}/>
                  <Route path="Page34" element={<Protected isLoggedIn={this.state.signedIn}> <Page34/> </Protected>}/>
                  <Route path="Page35" element={<Protected isLoggedIn={this.state.signedIn}> <Page35/> </Protected>}/>
                  <Route path="Page36" element={<Protected isLoggedIn={this.state.signedIn}> <Page36/> </Protected>}/>
                  <Route path="Page37" element={<Protected isLoggedIn={this.state.signedIn}> <Page37/> </Protected>}/>
                  <Route path="Page38" element={<Protected isLoggedIn={this.state.signedIn}> <Page38/> </Protected>}/>
                  <Route path="Page39" element={<Protected isLoggedIn={this.state.signedIn}> <Page39/> </Protected>}/>
                  <Route path="Page40" element={<Protected isLoggedIn={this.state.signedIn}> <Page40/> </Protected>}/>
                  <Route path="Page41" element={<Protected isLoggedIn={this.state.signedIn}> <Page41/> </Protected>}/>
                  <Route path="Page42" element={<Protected isLoggedIn={this.state.signedIn}> <Page42/> </Protected>}/>
                  <Route path="Page43" element={<Protected isLoggedIn={this.state.signedIn}> <Page43/> </Protected>}/>
                  <Route path="Page44" element={<Protected isLoggedIn={this.state.signedIn}> <Page44/> </Protected>}/>
                  <Route path="Page45" element={<Protected isLoggedIn={this.state.signedIn}> <Page45/> </Protected>}/>
                  <Route path="Page46" element={<Protected isLoggedIn={this.state.signedIn}> <Page46/> </Protected>}/>
                  <Route path="Page47" element={<Protected isLoggedIn={this.state.signedIn}> <Page47/> </Protected>}/>
                  <Route path="Page48" element={<Protected isLoggedIn={this.state.signedIn}> <Page48/> </Protected>}/>
                  <Route path="Page49" element={<Protected isLoggedIn={this.state.signedIn}> <Page49/> </Protected>}/>
                  <Route path="Page50" element={<Protected isLoggedIn={this.state.signedIn}> <Page50/> </Protected>}/>
                  <Route path="Page51" element={<Protected isLoggedIn={this.state.signedIn}> <Page51/> </Protected>}/>
                  <Route path="Page52" element={<Protected isLoggedIn={this.state.signedIn}> <Page52/> </Protected>}/>
                  <Route path="Page52_5" element={<Protected isLoggedIn={this.state.signedIn}> <Page52_5/> </Protected>}/>
                  <Route path="Page53" element={<Protected isLoggedIn={this.state.signedIn}> <Page53/> </Protected>}/>
                  <Route path="Page54" element={<Protected isLoggedIn={this.state.signedIn}> <Page54/> </Protected>}/>
                  <Route path="Page55" element={<Protected isLoggedIn={this.state.signedIn}> <Page55/> </Protected>}/>
                  <Route path="Page56" element={<Protected isLoggedIn={this.state.signedIn}> <Page56/> </Protected>}/>
                  <Route path="Page57" element={<Protected isLoggedIn={this.state.signedIn}> <Page57/> </Protected>}/>
                  <Route path="Page58" element={<Protected isLoggedIn={this.state.signedIn}> <Page58/> </Protected>}/>
                  <Route path="Page59" element={<Protected isLoggedIn={this.state.signedIn}> <Page59/> </Protected>}/>
                  <Route path="Page60" element={<Protected isLoggedIn={this.state.signedIn}> <Page60/> </Protected>}/>
                  <Route path="Page61" element={<Protected isLoggedIn={this.state.signedIn}> <Page61/> </Protected>}/>
                  <Route path="Page62" element={<Protected isLoggedIn={this.state.signedIn}> <Page62/> </Protected>}/>
                  <Route path="Page63" element={<Protected isLoggedIn={this.state.signedIn}> <Page63/> </Protected>}/>
                  <Route path="Page64" element={<Protected isLoggedIn={this.state.signedIn}> <Page64/> </Protected>}/>
                  <Route path="Page65" element={<Protected isLoggedIn={this.state.signedIn}> <Page65/> </Protected>}/>
                  <Route path="Page66" element={<Protected isLoggedIn={this.state.signedIn}> <Page66/> </Protected>}/>
                  <Route path="Page67" element={<Protected isLoggedIn={this.state.signedIn}> <Page67/> </Protected>}/>
                  <Route path="Page68" element={<Protected isLoggedIn={this.state.signedIn}> <Page68/> </Protected>}/>
                  <Route path="Page69" element={<Protected isLoggedIn={this.state.signedIn}> <Page69/> </Protected>}/>
                  <Route path="Page70" element={<Protected isLoggedIn={this.state.signedIn}> <Page70/> </Protected>}/>
                  <Route path="Page71" element={<Protected isLoggedIn={this.state.signedIn}> <Page71/> </Protected>}/>
                  <Route path="Page72" element={<Protected isLoggedIn={this.state.signedIn}> <Page72/> </Protected>}/>
                  <Route path="End" element={<Protected isLoggedIn={this.state.signedIn}> <End/> </Protected>}/>
                </Route>
              </Routes>
            </div>

          </HashRouter>

        </div>

    );
  }
}

export default App;